import React, { useMemo, useState } from 'react';
import { IServiceBasePageView, ServiceBasePage } from './ServiceBasePage';
import {
	IServiceManagementPopup,
	IServiceOrderOrCancel,
	ServiceManagementPopup,
	ServiceOrderAndCancelSteppes,
} from '../../../modals';
import { IServiceBasePageData, getServiceBasePageData, getStatus } from './ServiceBasePageData';
import { useAddonServicesContext, useApplicationContext } from '../../../contexts';
import { useSearchParams } from 'react-router-dom';
import { ServiceStatus } from '../../../models';

export const ServicePage = () => {
	// ************************************
	// Properties
	// ************************************

	const { activeBrand, activeTheme } = useApplicationContext();
	const { updateAddonStates } = useAddonServicesContext();

	// ************************************
	// Lifecycle
	// ************************************

	const [popupData, setPopupData] = useState<IServiceManagementPopup>();
	const [queryParams, setQueryParams] = useSearchParams();

	// ************************************
	// Data handling
	// ************************************

	// Function: Handles data based on various properties and parses into view data
	const _handlePageData = async (config: IServiceBasePageData) => {
		let viewData: IServiceBasePageView | undefined = await getServiceBasePageData({
			...config,
			...{
				onClickServiceHandling: (value: IServiceOrderOrCancel) => {
					setPopupData(value);
				},
			},
		});

		if (viewData) {
			await _handleQueryParamStatus(viewData, config);
		}

		return viewData;
	};

	// Function: If returning from or has set parameters, popup will appear
	const _handleQueryParamStatus = async (viewData: IServiceBasePageView, config: IServiceBasePageData) => {
		let queryParamState = queryParams?.get('orderStatus') as ServiceOrderAndCancelSteppes | undefined;

		if ((queryParamState == 'SUCCESS' || queryParamState == 'FAILURE') && viewData) {
			const { translations, user, account } = config;
			const { userData, installation } = user;
			const status: ServiceStatus = await getStatus(config);

			if (queryParamState == 'SUCCESS') queryParamState = 'REDIRECT_SUCCESS';

			setPopupData({
				status,
				installation,
				userData,
				account,
				page: translations,
				predefinedStatusStep: queryParamState,
			});
		}
	};

	// ************************************
	// Render Functionality
	// ************************************

	const _renderServiceManagementPopup = useMemo(() => {
		if (popupData) {
			return (
				<ServiceManagementPopup
					{...popupData}
					brand={activeBrand}
					theme={activeTheme}
					onClose={() => {
						setQueryParams(undefined);
						setPopupData(undefined);
						updateAddonStates();
					}}
				/>
			);
		}
	}, [popupData, activeBrand, activeTheme]);

	// ************************************
	// Render
	// ************************************

	return (
		<>
			<ServiceBasePage handleData={_handlePageData} />
			{_renderServiceManagementPopup}
		</>
	);
};
