import { IconType } from '@fjordkraft/fjordkraft.component.library';
import { _getTextPlankPrefabTemplate } from '.';
import { IStatePlank } from '../../../components';
import { Constants } from '../../../data';
import { ICustomerAccountInformation } from '../../../models';
import { AllowFeature, createString, getText } from '../../../services';
import { IHomePageData } from '../HomePageData';
import { PrimaryPlankTemplate } from '../../../Prefabs/Templates';
import {
	getSteddiStartDate,
	getSteddiStatus,
} from '../../ServicesPagesWrapper/ServicePage/Datahandling/UniqueServices';
import { format } from 'date-fns';

export const SteddiPlank = (props: IHomePageData): IStatePlank | undefined => {
	const { user, translations, services } = props;
	const { userData } = user;
	const { customerServiceFeature } = services;

	if (AllowFeature(Constants.features.steddi, translations, services.user, customerServiceFeature)) {
		if (userData?.accounts && userData.accounts.length > 0) {
			return {
				promise: fetchPredictablePaymentPlank(props),
			};
		}
	}
};

const fetchPredictablePaymentPlank = async (props: IHomePageData) => {
	const { activeTheme, translations, user, services } = props;
	const { userData } = user;

	const steddiStatus = await getSteddiStatus(userData.accounts, user.installation?.accountId, services.GETTYPED);

	if (steddiStatus == 'ACTIVE' || steddiStatus == 'ACTIVE_FUTURE') {
		let title = '';
		let description = '';
		let text: string = '';

		const foundSteddiAccount = userData.accounts.find(
			(account) => account.accountId == user.installation?.accountId
		);

		if (steddiStatus == 'ACTIVE' && foundSteddiAccount && foundSteddiAccount.steddiInfo) {
			title = getText('plankPredictablePaymentTitle', translations);
			description = createString(getText('plankPredictablePaymentDesc', translations), {
				amount: foundSteddiAccount.steddiInfo.monthlyAmount,
			});
			text = createString(getText('plankPredictablePaymentValue', translations), {
				amount: foundSteddiAccount.steddiInfo.balance,
			});
		} else if (steddiStatus == 'ACTIVE_FUTURE') {
			title = createString(getText('plankPredictablePaymentActiveFuture', translations), {
				date: format(getSteddiStartDate(), 'dd.MM.yyyy'),
			});
		}

		return _getTextPlankPrefabTemplate({
			theme: activeTheme,
			brand: 'brand-steddi',
			title: title,
			description: description,
			rightTitle: text,
			template: PrimaryPlankTemplate(activeTheme, 'single'),
			link: Constants.paths.predictablePayment,
			iconRight: IconType.ChevronRight,
			iconLeft: IconType.Steddi,
		});
	}
};
